import type { ApolloQueryResult } from '@apollo/client';
import { getApolloClient } from '~/singlePageApp/getApolloClient';
import { hasKey, isNil, isNotNil } from '~/utilities/type-guards';
import type { GetDatadogDataQuery } from './__gql/getDatadogData.gql.gen';
import { GetDatadogDataDocument } from './__gql/getDatadogData.gql.gen';

export type DatadogData = {
  displayName?: string;
  email?: string;
  accountName: string;
  accountKey: string;
  plan?: string | undefined;
  createdAt?: string;
  version: string;
};

export type AnonymousMetricsData = {
  version: string;
};

export const getDatadogData = async (
  client = getApolloClient({}, {}, 'AnonymousSchema'),
): Promise<AnonymousMetricsData | DatadogData> => {
  const { data, error }: ApolloQueryResult<GetDatadogDataQuery> = await client.query({
    query: GetDatadogDataDocument,
  });

  if (isNotNil(error)) {
    throw new Error(`Error: ${JSON.stringify(error)}`);
  }

  if (isNil(data.currentUser) || isNil(data.currentAccount)) {
    return {
      version: data.appMetadata.sha,
    };
  }

  return {
    displayName: data.currentUser.name ?? undefined,
    email: data.currentUser.email,
    accountName: data.currentAccount.displayName,
    accountKey: data.currentAccount.key,
    plan: data.currentAccount.plan?.name,
    createdAt: data.currentAccount.createdAt,
    version: data.appMetadata.sha,
  };
};

export const isDatadogData = (data: AnonymousMetricsData | DatadogData): data is DatadogData => {
  return hasKey(data, 'email');
};
